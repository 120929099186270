html
{
  background-image: url(./pics/common/bg/bg250px.jpg);
  font-size: 0.8em;
  font-family: "Noto Sans JP", sans-serif;
  color: #2b2b2b;
  font-size: 1em;
  scroll-behavior: smooth;
}

p
{
}

body
{
  max-width: 1200px;
  margin: 0 auto 0 auto;
  background-color: #fff;
  color: #2b2b2b;
}

/*
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
*/

.App-header {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.copany-name
{
  text-align: left;
  display: flex;
  margin: 0.5em;
  font-family: "Noto Sans JP", sans-serif;
}

.App-header .header-contact
{
  display: flex;
  margin-left: auto;
  justify-content: center;
}

.App-header a 
{
  text-decoration: none;
}

.App-header a .contact-buton
{
  text-align: right;
  align-items: center;
  background-color: #CBA243;
  border: #CBA243 solid 0.1em;
  font-size: 0.7em;
  font-weight: bold;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  transition: 0.5s ;
}

.App-header a .contact-buton:hover
{
  background-color: #fff;
  color: #CBA243;
  transition: 0.5s ;
}

.App-header .copany-name .logo
{
  display: flex;
  justify-content: center;
  align-items: center;
}

.App-header .copany-name .logo .App-logo 
{
  height: 2em;
  pointer-events: none;
  margin-right: 0.3em;
}

.copany-title div
{
  margin: -0.2em;
  font-weight: bold;
}

.body
{
  margin: 1.5em;
}

.body h2
{
  color: #CBA243;
  font-weight: bold;
  font-size: 1.5em;
  margin: 1em 0 0 0;
  font-family: "M PLUS Rounded 1c", sans-serif;
}

.body h3
{
  color: #CBA243;
  font-weight: bold;
  font-size: 1.1em;
  margin: 1.2em 0 0 0;
  font-family: "M PLUS Rounded 1c", sans-serif;
}

.App-footer {
  display: flex; 
  flex-direction: column;
  align-items: left;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  background-color: #F6F3F3;
}

.App-footer .copany-name .logo .App-logo 
{
  height: 1.6em;
  pointer-events: none;
  margin-right: 0.3em;
}

.footer-link
{
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin-left: auto;
}

.footer-link a
{
  width: fit-content;
  display: block;
  text-decoration: none;
  font-size: 0.7em;
  margin: 0 0 0.4em 0;
  font-family: "Noto Sans JP", sans-serif;
  color: #2b2b2b;
  font-weight: bolder;
}

.footer-link a:hover
{
  text-decoration: underline;
}

.copyright
{
  font-size: 0.6em;
  padding: 0.5em;
  text-align: center;
}

.App-link {
  color: #61dafb;
}

.font04em
{
  font-size: 0.4em;
}

.font06em
{
  font-size: 0.6em;
}

.font09em
{
  font-size: 0.9em;
}

.font11em
{
  font-size: 1.1em;
}

.no-margin
{
  margin: 0px !important;
}

/*====================================*/

.home-headerImage
{
  width: 100%;
  height: 25em;
  overflow-y: hidden;
  object-fit: cover;
}

.body ul
{
  margin-top: 0;
}

.body ul li
{
  word-wrap: break-word;
}

.body .inline
{
  display: flex;
  margin: 0 auto;
}

.body .inline-fit-content
{
  margin: 0 1em;
}

.body .inline-flex20
{
  width: 20%;
}

.body .inline-flex50
{
  width: 50%;
}

.body .inline-flex80
{
  width: 80%;
}

.body #representative
{
  margin: 3em auto;
  max-width: 700px;
}

.body #representative img
{
  width: 30%;
  overflow-y: hidden;
  object-fit: cover;
}

.body #representative
{
  margin: 3em auto;
  max-width: 700px;
}

.body #representative .inline-flex80
{
  margin: 0 1em;
}

.body .name-pronounce
{

}

.body .name-chinese
{
  font-size: 2em;;
}


@media screen and (max-width: 712px) {
  .home-headerImage
  {
    height: 10em;
  }

  .body .inline
  {
    display: block;
  }

  .body .inline-fit-content,
  .body .inline-flex20,
  .body .inline-flex50,
  .body .inline-flex80
  {
    width: 100% !important;
  }

  .body .inline-fit-content
  {
    margin:0 auto;
  }

  .body #representative
  {
    margin: 3em 0;
    max-width: 100%;
  }

  .body #representative img
  {
    width: 70%;
    margin: 0 15%;
  }

  .body #representative .inline-flex20
  {
    text-align: center;
  }

  .body #representative .inline-flex80
  {
    margin: 1em 0;
  }
}

/*====================================*/

#contact
{
  max-width: 700px;
  margin: 0 auto;
}

form
{
  text-align: center;
}

.body form h2
{
  text-align: left;
}

form div
{
  text-align: left;
  margin: 0 auto;
  width: 100%;
}

form label
{
  font-weight: bold;
}

form .input
{
  padding: 1.5%;
  margin-bottom: 5px;
  width: 97%;
}

form textarea
{
  padding: 1.5%;
  height: 100px;
  width: 97%;
}

form .input,
form textarea
{
  background-color: #dcdddd;
  border: 0px;
  border-radius: 5px;
}

form .input:valid,
form textarea:valid
{
  background-color: rgb(232, 240, 254);
}

form .button
{
  background-color: #d4dcd6;
  padding: 5px;
  margin: 10px 0;
  width: 100px;
  border-radius: 5px;
  font-weight: bold;
  transition: 0.2s ;
}

form .button:hover
{
  filter:drop-shadow(5px 5px 5px #4e4e4e);
  transition: 0.2s ;
}

.grecaptcha-badge
{
  display:none;
}

/*====================================*/

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


